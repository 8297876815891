$primary: #376E90 !default;
$theme-contrast-color: #fff !default;
$theme1: #EC7806;

$border-width: 1px;
$theme-colors: (
        'theme1': $theme1,
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$btn-font-weight: bold;
$headings-font-weight: 900;
$enable-rounded: true;
$enable-shadows: false;
$link-decoration: underline;
$nav-link-padding-x: .5rem;
$border-radius: 0px;
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-border-radius-lg: $border-radius;
$headings-color: $primary;
$btn-padding-x: 1rem;
$navbar-nav-link-padding-x: 1rem;

$scale-factor: .98;

@import "~daterangepicker";

// import font
@import url("https://use.typekit.net/wpi8afb.css");
$font-family-base: "exo-2-variable", sans-serif;

@import '@/src/scss/main.scss';

// SCSS Components
@import '@/src/scss/components/mijn-sidebar.scss'; // Mijn omgeving css (sidebar theme)
@import '@/src/scss/components/dropdown-sub-menu.scss';
@import '@/src/scss/components/hero-video.scss'; // Hero video

.topbar-card--link {
  @extend .nav-link;
}

.video-hero-overlay-opaque {
  display: block !important;
  background-color: $primary;
  opacity: .9 !important;
}

#vimeo-player {
  top: 40% !important;
}

.hero-content--container {
  margin-top: 6%;
  height: 300px !important;
  @include media-breakpoint-up(lg) {
    height: unset !important;
  }
}

.hero-flavor-01 {
  bottom: -3px;
}

.video-hero-wrapper {
  margin-bottom: -80px;
  @extend .z-r1;
  .container {
    @extend .position-absolute;
    @extend .text-center;
    height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .video-hero--content {
      display:none;
    }
    .video-hero--title {
      display: none;
    }
    .video-hero--subtitle {
      display: none;
    }
    @include media-breakpoint-up(md) {
      height: 500px;
    }
  }
  @include media-breakpoint-up(sm) {
    height: 600px;
  }
  @include media-breakpoint-up(md) {
    height: 400px;
  }
  @include media-breakpoint-up(lg) {
    height: 475px;
  }
  @include media-breakpoint-up(xl) {
    height: 500px;
  }
  @include media-breakpoint-up(xxl) {
    height: 600px;
  }
}

.wrapper {
  .sidebar {
    margin-bottom: 5rem;
  }
}

nav.navbar {
  z-index: 99;
}

.hero-flavor-02 {
  width: 400px;
  position: absolute;
  right: -225px;
  top: -225px;
  @include media-breakpoint-up(md) {
    width: 500px;
    position: absolute;
    left: -175px;
    top: -300px;
  }
}

nav.navbar .nav-link {
  color: $primary !important;
  transition: background-color .3s, color .3s;
  font-weight: bold;
  &:hover {
    background-color: #f5f5f5;
    color: darken($primary, 15%) !important;
  }
}

.navbar-brand--img {
  height: 3.5rem !important;
  @include media-breakpoint-up(lg) {
    height: 3rem !important;
  }
}

.dropdown {
  .dropdown-toggle::after {
    display: none;
  }
}

.banner-section {
  img {
    min-height: 400px;
    object-fit: cover;
  }
}

.banner-img {
  max-height: 500px;
  object-fit: cover;
  height: 100% !important;
}

.full-width-split-action-banner {
  .banner-row {
    overflow: hidden;
  }
  .bg--theme {
    @extend .bg-theme1;
  }
  .btn--theme {
    @extend .btn-light, .text-primary;
    i {
      display: none;
    }
  }
  .column-end {
    .container {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: '';
      height: 350px;
      width: 350px;
      position: absolute;
      top: -182px;
      background-image: url('../img/flavor-02.png');
      background-size: contain;
      right: -174px;
      z-index: 0;
      @include media-breakpoint-up(sm) {
        top: unset;
        bottom: -195px;
        right: -105px;
      }
    }
  }
}

.planner-table {
  thead {
    th {
      background: $theme1 !important;
      color: $white !important;
    }
  }
}

.fit--cover {
  object-fit: cover;
  opacity: 0.6;
}

.fade--left:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  color: $primary;
  width: 100%;
  height: 100%;
  border-collapse: separate;
  box-shadow: inset 230px 0 165px -30px;
}

.ticket-list-item--inner {
  @extend .border;
}

.footer--bottom {
  &::after {
    content: '';
    height: 350px;
    width: 350px;
    position: absolute;
    top: 69px;
    background-image: url('../img/flavor-02.png');
    background-size: contain;
    right: -145px;
    z-index: 0;
    @include media-breakpoint-up(md) {
      right: unset;
      top: unset;
      left: -145px;
      bottom: -165px;
    }
  }
}

.nav-item {
  @extend .list-unstyled;
}

.thin {
  font-weight: 200;
}
.footer-brand--img {
  height: 80px;
}

.social-sidebar {
  position: fixed;
  background: $primary;
  right: 0;
  z-index: 99;
  top: 63%;
  padding: 0.2rem 0.5rem 0.5rem 0.5rem;
  border: 1px solid #fff;
  .social--icons {
    color: white;
    display: block !important;
  }
}

.img-cover {
  height: 200px;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-up(md) {
    background-position: left;
    height: 500px !important;
  }
  @include media-breakpoint-up(xxl) {
    height: 620px !important;
  }
  &.home {
    height: 500px;
  }
}

.img-cover--alt {
  min-height: 240px;
  @include media-breakpoint-up(md) {
    background-position: left;
    height: 450px;
  }
  @include media-breakpoint-up(xxl) {
    height: 490px;
  }
  &.home {
    height: 450px;
  }
}

.footer-topbar-logos {
  img {
    width: 100px;
    height: auto;
  }
}

.breadcrumb--bar {
  background: lighten($theme1, 45%) !important;
}

.animated-link {
  i {
    @extend .ml-1;
    transition: transform .2s;
  }
  &:hover {
    i {
      transform: translateX(6px);
    }
  }
}

.content-search {
  @extend .my-5;
  .content-search--form {
    margin: 0;
  }
}

.badge-wrapper {
  width: 60px;
  right: 0rem;
  @extend .mr-4
}

.img-wrapper {
  .date-badge {
    position: absolute;
    bottom: 0;
    right: 0;
    @extend .p-2, .mr-4, .bg-white;
    .date-badge--string {
      @extend .font-weight-bold, .text-primary;
    }
  }
}

.agenda-split--right {
  @extend .pl-lg-4, .col-lg-6;
}

.agenda-split--left {
  @extend .col-md-6;
  .agenda-split--line-break {
    @extend .mt-4;
  }
  .intrest-badge {
    display: none !important;
  }
}

.agenda-split--left, .agenda-item, .news-item {
  .card-title {
    @extend .mb-1;
  }
  .card-body {
    @extend .pt-3;
  }
  .card {
    @extend .bg-transparent, .mb-4;
  }
}

.card-stretch-hover {
  .card {
    width: 100%;
    transition: transform .3s;
  }
  .fa-chevron-right {
    transition: margin-left .2s;
  }
  &:hover {
    .card {
      transform: scale($scale-factor);
    }
    .fa-chevron-right {
      margin-left: .5rem !important;
    }
  }
}


.footer-menu {
  .bold, .footer-menu--item {
    font-weight: inherit !important; // reset
    a {
      text-decoration: none;
      color: $white;
      display: block;
      @extend .mb-2;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.footer-contact {
  a {
    text-decoration: none;
    color: $white;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-socials {
  a {
    i {
      color: $white;
      font-size: 1.8rem;
    }
  }
}

.footer-wave {
  width: 100%;
  position: relative;
  bottom: -1px;
}

.footer-flavor-1 {
  position: absolute;
  bottom: -75px;
  left: -75px;
  width: 200px;
  @include media-breakpoint-down(md) {
    display: none;
  }
  @include media-breakpoint-down(lg) {
    width: 150px;
    left: -50px;
    bottom: -50px;
  }
}

.footer-flavor-backdrop {
  width: 80vw;
  position: absolute;
  opacity: .5;
  bottom: 65%;
  left: -10%;
  z-index: -9;
  max-width: 1500px;
  @include media-breakpoint-up(md) {
    width: 60vw;
    bottom: 50%;
  }
  @include media-breakpoint-up(lg) {
    width: 60vw;
    bottom: 0;
  }
}

.footer-flavor-2 {
  position: absolute;
  bottom: 35px;
  right: calc(-300px - -30vw);
  width: 85px;
  max-width: 263px;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.footer-flavor-3 {
  position: absolute;
  bottom: 35px;
  right: 0;
  width: 100px;
}

.video-banner--body {
  @extend .bg-primary, .border-0, .text-primary;
}

.mobile-menu {
  display: contents;
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
  .nav-link {
    color: $dark !important;
  }
}

.breadcrumb--bar {
  background-color: $theme1;
  a {
    text-decoration: none;
  }
}

.part-category-nav {
  a {
    text-decoration: none;
  }
}

.part--card {
  .card-img-top {
    @extend .rounded;
  }
  .card-body {
    @extend .py-3
  }
  .card-title {
    a {
      @extend .text-decoration-none;
    }
  }
}

.accordion button::after {
  color: $primary;
}

body > .wrapper {
  margin-bottom: -85px;
}

.account-button--style {
  @extend .btn-primary;
}

.has-user-content {
  table {
    tr th:first-child {
      border-top-left-radius: 10px;
    }
    tr th:last-child {
      border-top-right-radius: 10px;
    }
    th {
      border: transparent;
    }
    td {
      border-color: #dadada !important;
    }
  }

}

.form-group {
  .text-muted {
    font-size: 0.7em;
  }
}
